import axios from '@/utils/request'
import qs from 'qs'

export function pageUtilsInstall(Vue) {
    // 顶部消息提示
    Vue.prototype.$tip = function (type, msg) { // type:0 成功; 1 警告; 2 错误
        this.$message({
            showClose: false,
            message: msg,
            // type: type === 0 ? 'warning' : 'success'
            // type: type === 0 ? 'success' : (type === 1 ? 'warning' : 'error')
            type: type === 0 ? 'success' : (type === 1 ? 'warning' : (type === 2 ? 'error' : 'info'))
        })
    }
    Vue.prototype.$http = {
        get: function (url, params) {
            return axios({
                url: url,
                method: 'get',
                params
            })
        },
        post: function (url, params) {
            return axios({
                url,
                method: 'post',
                data: params
            })
        },
        payload: function (url, params) {
            return axios({
                url,
                method: 'post',
                data: params,
                headers: { "content-type": "application/x-www-form-urlencoded" }
            })
        },
        download: async function (url, params, excelName) {
            const rs = await axios({
                url,
                method: 'get',
                params,
                responseType: 'blob'
            })
            const contentDisposition = rs.headers['content-disposition']
            const fileName = contentDisposition ? decodeURI(contentDisposition.split('=')[1]) : ''
            const link = document.createElement('a')
            link.href = window.URL.createObjectURL(rs.data)
            if (fileName) {
                link.download = fileName
            } else {
                link.download = excelName
            }
            link.click()
            window.URL.revokeObjectURL(link.href)
            link.remove()
        },
    }
    // 路由返回上级
    Vue.prototype.$closeCurrentPage = function (path) {
        this.$store.dispatch('delVisitedViews', this.$route).then((views) => {
            if (path) {
                // 验证是否原本就带了参数的
                path = /\?\b[A-Za-z0-9]+=/.test(path) ? path + '&from=tap' : path + '?&from=tap'
                this.$router.push(path)
            } else {
                this.$router.go(-1)
            }
            // const latestView = views.slice(-1)[0]
            // if (latestView) {
            //   latestView.path = /\?\b[A-Za-z0-9]+=/.test(latestView.path) ? latestView.path + '&from=tap' : latestView.path + '?&from=tap'
            //   this.$router.push(latestView.path)
            // } else {
            //   this.$router.push('/')
            // }
        })
    }
    Vue.prototype.$closePageTo = function (path) {
        this.$store.dispatch('delVisitedViews', this.$route).then((views) => {
            this.$router.push(path)
        })
    }
    Vue.prototype.$addIndexPage = function (path) {
        const first = { name: '首页', path: '/index', fullPath: '/index' }
        this.$store.dispatch('addVisitedViews', first)
    }
    // 深拷贝
    Vue.prototype.$deepClone = function (source) {
        let res = deepClone(source)
        return res
    }
    function deepClone(source) {
        let target
        if (typeof source === 'object') {
            target = Array.isArray(source) ? [] : {}
            for (let key in source) {
                // console.log(source);
                if (Object.prototype.hasOwnProperty.call(source, key)) {
                    // source.key = true
                    if (!source[key] && source[key] !== 0) {
                        target[key] = null
                    } else if (typeof source[key] !== 'object') {
                        target[key] = source[key]
                    } else {
                        target[key] = deepClone(source[key])
                    }
                }else{
                    // this.$set(source, key, true)
                }
                // this.$set(source, key, true)
            }
        } else {
            target = source
        }
        return target
    }
    // 获取是否有按钮权限
    Vue.prototype.$getRole = function (code) {
        let roles = this.$store.getters.getRole
        let role = roles.findIndex(item => item === code)
        return role !== -1
    }
    // 表单校验封装
    Vue.prototype.$validate = function (formName) {
        console.log(formName)
        return new Promise((resolve, reject) => {
            this.$refs[formName].validate((rs) => {
                if (rs) {
                    console.log(rs)
                    resolve(rs)
                }
            })
        })
    }
    // 存储请求域名
    Vue.prototype.$baseApi = function () {
        console.log(process.env.VUE_APP_BASE_URL, window.location.protocol + "//" + window.location.host);
        return process.env.VUE_APP_BASE_URL
        // return window.location.protocol + "//" + window.location.host  // 本地无法下载模板
    }

    // Vue.mixin({
    //     data() {
    //         return{
    //             pageParams: {
    //                 page: 1,
    //                 rows: 20,
    //                 sortName: null,
    //                 sortOrder: null
    //             },
    //             tablePage: {
    //                 loading: false,
    //                 total: 0,
    //                 list: []
    //             }
    //         }
    //     },
    //     methods: {
    //         async $getTableList() {
    //             this.tablePage.loading = true
    //             try {
    //                 await this.getTableList()
    //             } finally {
    //                 this.tablePage.loading = false
    //             }
    //         },
    //         // 分页
    //         $handleSizeChange(val) {
    //             this.$setPage(val)
    //             this.pageParams.page = 1
    //             this.pageParams.rows = val
    //             this.$getTableList()
    //         },
    //         $setPage(val) {
    //             if (window.localStorage) {
    //                 const pageSizes = JSON.parse(window.localStorage.pageSizes || '{}') || {}
    //                 pageSizes[this.$route.path] = val
    //                 window.localStorage.pageSizes = JSON.stringify(pageSizes)
    //             }
    //         },
    //         $handleCurrentChange(val) {
    //             this.pageParams.page = val
    //             this.$getTableList()
    //         },
    //     }
    // })
    
    
}